var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-10",attrs:{"id":"overview"}},[_c('v-app-bar',{attrs:{"app":""}},[_c('v-toolbar-title',[_vm._v("تقرير شامل")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","dark":""},on:{"click":function($event){return _vm.$print(_vm.$refs.print)}}},[_c('v-icon',[_vm._v("la-print")]),_vm._v(" طباعة ")],1)],1),_c('v-card',{ref:"print",staticClass:"pa-10"},[_c('center',{staticClass:"printHeader"},[_c('h2',[_vm._v("التقرير العام")])]),_c('v-row',[_c('v-col',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"تاريخ البداية","outlined":"","dense":"","hide-details":"","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"disabled":!_vm.checkPermission('overview_date'),"max":_vm.max,"min":_vm.min,"label":"تاريخ البداية"},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"تاريخ النهاية","outlined":"","dense":"","hide-details":"","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"disabled":!_vm.checkPermission('overview_date'),"max":_vm.max,"min":_vm.min,"label":"تاريخ النهاية"},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.supervisors,"item-text":"username","item-value":"idUser","outlined":"","dense":"","hide-details":"","label":"المشرف"},on:{"change":function($event){return _vm.setDelegates()}},model:{value:(_vm.selectedSuperVisor),callback:function ($$v) {_vm.selectedSuperVisor=$$v},expression:"selectedSuperVisor"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.delegates,"item-text":"username","item-value":"idUser","outlined":"","dense":"","hide-details":"","multiple":"","label":"المندوب"},model:{value:(_vm.selectedDelegate),callback:function ($$v) {_vm.selectedDelegate=$$v},expression:"selectedDelegate"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"disabled":_vm.userData.overviewSellPrice > 0,"items":_vm.sellPrices,"item-text":"sellPriceName","item-value":"idSellPrice","outlined":"","dense":"","hide-details":"","label":"ترتيب حسب سعر البيع"},model:{value:(_vm.selectedSellPrice),callback:function ($$v) {_vm.selectedSellPrice=$$v},expression:"selectedSellPrice"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary","block":"","dark":""},on:{"click":function($event){return _vm.search()}}},[_vm._v(" بحث ")])],1)],1),_c('br'),_c('v-data-table',{key:_vm.forceRerender,attrs:{"items":_vm.selectedSellPrice == 0
      ? _vm.report.data
      : _vm.report.data.filter(function (e) { return e.sellPriceId == _vm.selectedSellPrice; }),"items-per-page":2000,"hide-default-footer":"","headers":_vm.report.header,"multi-sort":""},scopedSlots:_vm._u([{key:"item.totalCustomers",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalCustomers.toLocaleString())+" ")]}},{key:"item.totalSelling",fn:function(ref){
      var item = ref.item;
return [(_vm.checkPermission('overview_visits'))?_c('router-link',{attrs:{"target":"_BLANK","to":'visits?delegate=' + item.idUser + '&date=' + _vm.startDate}},[_vm._v(_vm._s(item.totalSelling.toLocaleString()))]):_vm._e(),(!_vm.checkPermission('overview_visits'))?_c('div',[_vm._v(" "+_vm._s(item.totalSelling.toLocaleString())+" ")]):_vm._e()]}},{key:"item.totalRestores",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalRestores.toLocaleString())+" ")]}},{key:"item.totalRemaining",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalRemaining.toLocaleString())+" ")]}},{key:"item.totalOffers",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{attrs:{"target":"_BLANK","to":'discounts?delegate=' + item.idUser + '&date=' + _vm.startDate}},[_vm._v(_vm._s(item.totalOffers.toLocaleString()))])]}},{key:"item.totalGifts",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalGifts.toLocaleString())+" ")]}},{key:"item.totalDamaged",fn:function(ref){
      var item = ref.item;
return [(_vm.checkPermission('overview_damaged'))?_c('router-link',{attrs:{"target":"_BLANK","to":'damagedItems?delegate=' + item.idUser + '&date=' + _vm.startDate}},[_vm._v(_vm._s(item.totalDamaged.toLocaleString()))]):_vm._e(),(!_vm.checkPermission('overview_damaged'))?_c('div',[_vm._v(" "+_vm._s(item.totalDamaged.toLocaleString())+" ")]):_vm._e()]}},{key:"item.remain",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(( item.totalCustomers - item.invoicesCount - item.totalVisits ).toLocaleString())+" ")]}},{key:"item.invoicesCount",fn:function(ref){
      var item = ref.item;
return [(_vm.checkPermission('overview_access_sales'))?_c('router-link',{attrs:{"target":"_BLANK","to":'store?delegate=' +
      item.idUser +
      '&dateFrom=' +
      _vm.startDate +
      '&dateTo=' +
      _vm.endDate}},[_vm._v(_vm._s(item.invoicesCount.toLocaleString()))]):_vm._e(),(!_vm.checkPermission('overview_access_sales'))?_c('div',[_vm._v(" "+_vm._s(item.invoicesCount.toLocaleString())+" ")]):_vm._e()]}},{key:"item.username",fn:function(ref){
      var item = ref.item;
return [(_vm.checkPermission('overview_access_account'))?_c('router-link',{attrs:{"target":"_BLANK","to":'user/' + item.idUser}},[_vm._v(_vm._s(item.username))]):_vm._e(),(!_vm.checkPermission('overview_access_account'))?_c('div',[_vm._v(" "+_vm._s(item.username)+" ")]):_vm._e()]}},{key:"item.totalVisits",fn:function(ref){
      var item = ref.item;
return [(_vm.checkPermission('overview_visits'))?_c('router-link',{attrs:{"target":"_BLANK","to":'visits?delegate=' + item.idUser + '&date=' + _vm.startDate}},[_vm._v(_vm._s(item.totalVisits.toLocaleString()))]):_vm._e(),(!_vm.checkPermission('overview_visits'))?_c('div',[_vm._v(" "+_vm._s(item.totalVisits.toLocaleString())+" ")]):_vm._e()]}},{key:"item.firstInvoiceDate",fn:function(ref){
      var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.formatAMPM( _vm.compareDates(item.firstVisitDate, item.firstInvoiceDate) ))+" ")])]}},{key:"item.lastInvoiceDate",fn:function(ref){
      var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.formatAMPM( _vm.compareDates2(item.lastVisitDate, item.lastInvoiceDate) ))+" ")])]}},{key:"item.timeCompare",fn:function(ref){
      var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.startDateFixed( _vm.compareDates(item.firstVisitDate, item.firstInvoiceDate), _vm.compareDates2(item.lastVisitDate, item.lastInvoiceDate) ).replace("منذ", ""))+" ")])]}},{key:"item.late",fn:function(ref){
      var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.calculateLate(item.idUser) == -1 ? "..." : _vm.calculateLateDuration(_vm.calculateLate(item.idUser)))+" ")])]}},{key:"item.finalLate",fn:function(ref){
      var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.calculateLate(item.idUser) == -1 ? "..." : _vm.calculateLateDuration( _vm.finalLate( _vm.compareDates(item.firstVisitDate, item.firstInvoiceDate), _vm.compareDates2(item.lastVisitDate, item.lastInvoiceDate), _vm.calculateLate(item.idUser) ) ))+" ")])]}}],null,true)}),_c('div',{staticClass:"pa-10  footerGrid",staticStyle:{"font-size":"14px !important","float":"right","margin-top":"100px","width":"100%","margin":"30px"}},[_c('v-row',[_c('v-col',[_vm._v(" عدد الزبائن "),_c('br'),_vm._v(_vm._s(_vm.sum("totalCustomers"))+" ")]),_c('v-col',[_vm._v(" عدد الفواتير "),_c('br'),_vm._v(_vm._s(_vm.sum("invoicesCount"))+" ")]),_c('v-col',[_vm._v(" عدد الراجع "),_c('br'),_vm._v(_vm._s(_vm.sum("restoresCount"))+" ")]),_c('v-col',[_vm._v(" مجموع الفواتير "),_c('br'),_vm._v(_vm._s(_vm.sum("totalSelling"))+" ")]),_c('v-col',[_vm._v(" مجموع الراجع "),_c('br'),_vm._v(_vm._s(_vm.sum("totalRestores"))+" ")]),_c('v-col',[_vm._v(" مجموع الهدايا "),_c('br'),_vm._v(_vm._s(_vm.sum("totalGifts"))+" ")]),_c('v-col',[_vm._v(" مجموع العروض "),_c('br'),_vm._v(_vm._s(_vm.sum("totalOffers"))+" ")]),_c('v-col',[_vm._v(" مجموع التالف "),_c('br'),_vm._v(_vm._s(_vm.sum("totalDamaged"))+" ")]),_c('v-col',{staticClass:"success white--text"},[_vm._v(" الاجمالي "),_c('br'),_vm._v(_vm._s(_vm.sum("totalRemaining"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }